header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header-container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ========= CTA ========= */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

/* ========= HEADER SOCIALS ========= */

.header-socials {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: .8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header-socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ========= ME ========= */

.me {
    /* background: var(--color-primary); */
    /* background: linear-gradient(var(--color-primary), transparent); */
    width: 22rem;
    height: 30rem;
    position: absolute;
    /* left: calc(50% - 11rem); */
    left: 50%;
    transform: translateX(-50%);
    margin-top: 2.5rem;
    /* border-radius: 12rem 12rem 0 0 ; */
    /* overflow: hidden; */
    /* padding: 5rem 1.5rem 1.5rem 1.5rem; */
}

.me img {
    border-radius: 12rem 12rem 0 0 ;
}

/* ========= SCROLL DOWN ========= */

.scroll-down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* ========== MQ (MEDIUM DEVICES) =========*/


@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
        padding-top: 3rem;
    }
}

/* ========== MQ (SMALL DEVICES) =========*/


@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    header h1 {
        font-size: 1.5rem;
    }

    .cta :nth-child(1) {
        margin-right: 1rem;
    }

    .header-socials,
    .scroll-down {
        display: none;
    }
}

@media screen and (max-width: 360px) {
    .cta {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
}