.portfolio-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio-item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    height: 30rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio-item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio-item-image img {
    border-radius: 1.5rem;
    height: 12rem;
}

.portfolio-item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio-item-cta {
    display: flex;
    gap: 1rem;
    /* margin-bottom: 1rem; */
}



/* ========== MQ (MEDIUM DEVICES) =========*/


@media screen and (max-width: 1024px) {
    .portfolio-container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
        margin-top: 10px;
    }

    article {
        margin-top: 15px;
    }

    .portfolio-item-cta > :nth-child(1) {
        margin-right: 10px;
    }
}

/* ========== MQ (SMALL DEVICES) =========*/


@media screen and (max-width: 600px) {
    .portfolio-container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}