.about-container {
    display: grid;
    grid-template-columns: 40% 50%;
    gap: 10%;
}

.about-me {
    width: 100%;
    /* aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    display: grid;
    place-items: center; */
}

.about-me-image {
    border-radius: 2rem;
    overflow: hidden;
    /* transform: rotate(10deg); */
    /* transition: var(--transition); */
}

.about-me-image img {
    max-height: 130%;
}

.about-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about-card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 1.8rem;
    text-align: center;
    transition: var(--transition);
}

.about-card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about-icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about-card h5 {
    font-size: 0.95rem;
    color: var(--color-white);
}

.about-card small {
    font-size: 0.65rem;
    color: var(--color-light);
}

.about-content p {
    margin: 2rem 0 2.6rem;
}


/* ========== MQ (MEDIUM DEVICES) =========*/


@media screen and (max-width: 1024px) {
    .about-container {
        grid-template-columns: 1fr;
        gap: 0;
    }
    
    .about-me {
        width: 86%;
        margin: 2rem auto 4rem;
    }

    .about-content p {
        margin: 1rem 0 1.5rem;
    }

    .about-card {
        margin-top: 0.5rem;
    }
    
}

/* ========== MQ (SMALL DEVICES) =========*/


@media screen and (max-width: 600px) {
    .about-me {
        width: 90%;
        margin: 0 auto 1rem;
    }

    .about-cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about-card {
        padding: 1rem;
    }

    .about-content {
        text-align: center;
    }

    .about-content p {
        margin: 1.5rem 0;
    }
}